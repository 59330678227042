<template>
	<v-card />
</template>

<script>
export default {
	components: {},
	setup() {},
}
</script>
